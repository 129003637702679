import Vue from "vue";

export class apiUrl {}

apiUrl.install = function (Vue) {
  Vue.prototype.$API_URL = function () {
    return window.localStorage.getItem("API_URL");
  };
};

Vue.use(apiUrl);
