import Vue from "vue";
import VueLodash from "vue-lodash";
import filter from "lodash/filter";
import sum from "lodash/sum";
import findIndex from "lodash/findIndex";
import sumBy from "lodash/sumBy";

Vue.use(VueLodash, {
  name: "customLodash",
  lodash: { filter, sum, findIndex, sumBy },
});
