import Vue from "vue";

export class makeToast {}

makeToast.install = function (Vue) {
  Vue.prototype.$makeToast = function (title, message, variant) {
    this.$bvToast.toast(message, {
      title: title,
      variant: variant,
      solid: true,
    });
  };

  Vue.prototype.$successToast = function () {
    this.$bvToast.toast("Guardado con éxito", {
      title: "Todo bien",
      variant: "success",
      solid: true,
    });
  };

  Vue.prototype.$errorToast = function (error = "") {
    this.$bvToast.toast(`Ocurrió un error guardando... ${error}`, {
      title: "Ooops",
      variant: "danger",
      solid: true,
    });
  };
};

Vue.use(makeToast);
